import { Loader } from "@googlemaps/js-api-loader";

export default class GoogleMaps {
  constructor(lat, lng) {
    this.lat = parseFloat(lat);
    this.lng = parseFloat(lng);

    this.init();
  }

  init() {
    const loader = new Loader({
      apiKey: process.env.API_KEY_GOOGLE,
      version: "weekly",
    });

    loader.load().then(() => {
      const map = new google.maps.Map(document.getElementById("ipinfo-map"), {
        center: { lat: this.lat, lng: this.lng },
        zoom: 15,
      });
    }).catch(e => console.error("Error loading the Google Maps API: ", e));
  }
}
