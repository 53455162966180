export default class HoverInfo {
  constructor(element) {
    this.element = element;

    this.hoverInfo = document.querySelectorAll('.hover-info');
    this.hoverElement = document.querySelector('.hover-element');

    this.init();
  }

  init() {
    this.hoverInfo.forEach(element => {
      // Ajouter un écouteur d'événement pour le survol
      element.addEventListener('mouseenter', (event) => {
        this.hoverElement.textContent = event.target.textContent;
        this.hoverElement.classList.remove("d-none");
        this.hoverElement.classList.add("d-flex");
      });
      element.addEventListener('mouseout', (event) => {
        this.hoverElement.textContent = "";
        this.hoverElement.classList.remove("d-flex");
        this.hoverElement.classList.add("d-none");
      });
    });
  }
}
