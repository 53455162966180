export default class Wizard {
  constructor(element) {
    this.element = element;
    this.playerNumber = 2; //nombre de joueurs
    this.roundNow = 1; //numéro du tour
    this.playerArray = []; //tableau du dealer
    this.init();
  }

  static get SELECTORS() {
    return {
      //Élément HTML
      lessButton: document.querySelector("#less-player"),
      moreButton: document.querySelector("#more-player"),
      players: document.querySelector("#players"),
      start: document.querySelector("#start-game"),
      wizardStart: document.querySelector(".wizard__start"),
      wizardGame: document.querySelector(".wizard__game"),
      wizardTable: document.querySelector(".wizard__game__table__body"),
      wizardRoundTotal: document.querySelector(".wizard__round__total"),
      wizardRoundNow: document.querySelector(".wizard__round__now"),
      wizardDealer: document.querySelector(".wizard__game__dealer__now"),
    };
  }

  init() {
    Wizard.SELECTORS.lessButton.addEventListener("click", this.removePlayer.bind(this));
    Wizard.SELECTORS.moreButton.addEventListener("click", this.addPlayer.bind(this));
    Wizard.SELECTORS.start.addEventListener("click", this.startGame.bind(this));
  }

  removePlayer() {
    //Enlève un joueur
    if (this.playerNumber > 2) {
      document.getElementById("player" + this.playerNumber.toString()).remove();
      this.playerNumber--;
    } else {
      alert("Il doit avoir au moins 2 joueur");
    }
  }

  addPlayer() {
    //Ajoute un joueur
    if (this.playerNumber < 6) {
      this.playerNumber++;
      let newInput = document.createElement("input");
      newInput.setAttribute("id", "player" + this.playerNumber.toString());
      newInput.setAttribute("type", "text");
      newInput.setAttribute("aria-label", "Joueur " + this.playerNumber.toString());
      Wizard.SELECTORS.players.appendChild(newInput);
    } else {
      alert("Il y a un maximum de 6 joueur");
    }
  }

  startGame() {
    //Détermine le nombre de tour
    if (this.playerNumber === 4) {
      this.roundNumber = 15;
    }
    else if (this.playerNumber === 5){
      this.roundNumber = 12;
    }
    else if (this.playerNumber === 6){
      this.roundNumber = 10;
    }
    else{
      this.roundNumber = 20;
    }
    Wizard.SELECTORS.wizardRoundTotal.textContent = this.roundNumber;
    Wizard.SELECTORS.wizardRoundNow.textContent = this.roundNow;

    //Création du tableau des joueurs
    for (let i = 0; i < this.playerNumber; i++) {
      //Création de la section du joueur
      const playerId = i + 1;
      const newPlayer = document.createElement("tr");
      newPlayer.setAttribute("id", "table__player" + playerId.toString());
      Wizard.SELECTORS.wizardTable.appendChild(newPlayer);

      //Ajout du nom du joueur
      const playerName = document.getElementById("player" + playerId.toString()).value;
      const newPlayerName = document.createElement("td");
      newPlayerName.textContent = playerName;
      newPlayer.appendChild(newPlayerName);

      //Ajout de la section score
      let playerScore = 0;
      const newPlayerScore = document.createElement("td");
      newPlayerScore.textContent = playerScore.toString();
      newPlayer.appendChild(newPlayerScore);

      //Ajout de la section mise
      let playerBid = 0;
      const newPlayerBid = document.createElement("td");
      newPlayerBid.textContent = playerBid.toString();
      newPlayer.appendChild(newPlayerBid);

      //Ajout de la section levée
      let playerTook = 0;
      const newPlayerTook = document.createElement("td");
      newPlayerTook.textContent = playerTook.toString();
      newPlayer.appendChild(newPlayerTook);

      //Création du tableau pour le dealer
      this.playerArray.push(playerName);

      //Changement de scène
      if (i === this.playerNumber-1){
        Wizard.SELECTORS.wizardStart.classList.add("d-none");
        Wizard.SELECTORS.wizardGame.classList.remove("d-none");
        Wizard.SELECTORS.wizardGame.classList.add("d-flex");
      }
    }

    //Qui est le dealer
    Wizard.SELECTORS.wizardDealer.textContent = this.playerArray[0];
  }
}
