export default class SpotifyPlayer {
  constructor(element) {
    this.element = element;
    this.embedIframe = document.querySelector(".embed-iframe");
    this.init();
  }

  init() {
    this.playButton = document.querySelectorAll(".spotify__play, .spotify__play__hover");
    this.playButton.forEach((element) => {
      element.addEventListener("click", () => this.changeTrack(element.dataset.spotifylink));
    });
  }

  changeTrack(music) {
    window.onSpotifyIframeApiReady = IFrameAPI => {
      const element = this.controller ? document.querySelector(".spotify__player") : document.getElementById("embed-iframe");
      const options = {
        width: "30%",
        height: "152",
        uri: `spotify:track:${music}`
      };
      const callback = EmbedController => {
        this.controller = EmbedController;
        this.controller.addListener("ready", () => {
          console.log("ready");
        });
      };
      IFrameAPI.createController(element, options, callback);
      this.iframe = this.controller.iframeElement;
      this.iframe.classList.add("spotify__player");

      this.controller.play();

      if (!element.classList.contains("spotify__player")) {
        this.createCloseButton();
      }
    };
  }

  createCloseButton() {
    this.embedIframe.classList.remove("close");
    this.embedIframe.classList.add("open");
    this.close = document.createElement("button");
    this.minify = document.createElement("button");
    this.close.classList.add("embed-iframe-close");
    this.minify.classList.add("embed-iframe-minify");
    this.close.ariaLabel = "Fermer le lecteur de musique";
    this.close.title = "Fermer le lecteur de musique";
    this.minify.ariaLabel = "Réduire le lecteur de musique";
    this.minify.title = "Réduire le lecteur de musique";
    this.close.textContent = "X";
    this.minify.textContent = "-";
    this.embedIframe.appendChild(this.close);
    this.embedIframe.appendChild(this.minify);

    this.close.addEventListener("click", this.closePlayer.bind(this));
    this.minify.addEventListener("click", this.minifyPlayer.bind(this));
  }

  closePlayer() {
    this.embedIframe.classList.remove("open");
    this.embedIframe.classList.add("close");
    this.embedIframe.removeChild(this.iframe);
    this.embedIframe.removeChild(this.close);
    this.embedIframe.removeChild(this.minify);

    const embedDiv = document.createElement("div");
    embedDiv.id = "embed-iframe";
    this.embedIframe.appendChild(embedDiv);

    this.controller = null;
  }

  minifyPlayer() {
    const iframe = document.querySelector(".spotify__player");
    if (iframe.style.height !== "0px") {
      this.iframeHeight = iframe.style.height;
      iframe.style.height = "0px";
    } else {
      iframe.style.height = this.iframeHeight;
    }
  }
}
