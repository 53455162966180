export default class Canvas {
  constructor(element) {
    this.element = element;
    this.body = document.body;
    this.themeButton = document.querySelector(".theme");
    this.init();
  }

  init() {
    this.themeButton.addEventListener("click", this.changeTheme.bind(this));
    this.setTheme(localStorage.getItem("theme") || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"));
  }

  changeTheme() {
    this.setTheme(this.body.classList.toggle("theme-dark") ? "dark" : "light");
  }

  setTheme(theme) {
    localStorage.setItem("theme", theme);
    this.body.className = `theme-${theme}`;
    this.initCanvas(`theme-${theme}`);
  }

  initCanvas(themeClass) {
    const canvas = document.querySelector('canvas');
    const ctx = canvas.getContext('2d');
    const color = themeClass === "theme-dark" ? 0xffffffff : 0xff000000;

    function resizeCanvas() {
      const pixelRatio = window.devicePixelRatio || 1;
      canvas.width = window.innerWidth * pixelRatio;
      canvas.height = window.innerHeight * pixelRatio;
      canvas.style.width = `${window.innerWidth}px`;
      canvas.style.height = `${window.innerHeight}px`;
    }

    function drawNoise() {
      const imageData = ctx.createImageData(canvas.width, canvas.height);
      const buffer32 = new Uint32Array(imageData.data.buffer);
      const len = buffer32.length;

      for (let i = 0; i < len; i++) {
        buffer32[i] = Math.random() < 0.5 ? color : 0x00000000;
      }

      ctx.putImageData(imageData, 0, 0);
    }

    function loop() {
      drawNoise();
      requestAnimationFrame(loop);
    }

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    loop();
  }
}
