import GoogleMaps from "./GoogleMaps";

export default class IpInfo {
  constructor(element) {
    this.element = element;

    this.init();
  }

  init() {
    this.checkIp();
  }

  checkIp() {
    fetch(`https://ipinfo.io/json?token=${process.env.API_KEY_IPINFO}`).then(
      (response) => response.json()
    ).then(
      (jsonResponse) => {
        // Informations
        document.querySelector("#ipinfo-ip").textContent = `${jsonResponse.ip}`;
        document.querySelector("#ipinfo-city").textContent = `${jsonResponse.city}`;
        document.querySelector("#ipinfo-region").textContent = `${jsonResponse.region}, ${jsonResponse.country}`;
        document.querySelector("#ipinfo-hostname").textContent = `${jsonResponse.hostname}`;
        document.querySelector("#ipinfo-org").textContent = `${jsonResponse.org}`;
        document.querySelector("#ipinfo-timezone").textContent = `${jsonResponse.timezone}`;

        // Google Maps
        const coordinates = jsonResponse.loc.split(',');
        const lat = coordinates[0];
        const lng = coordinates[1];
        new GoogleMaps(lat, lng);
      }
    )
  }
}

