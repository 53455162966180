export default class Time {
  constructor(element) {
    this.element = element;
    this.init();
    this.updateTimeAtNextSecond();
  }

  static get SELECTORS() {
    return {
      displayTime: document.querySelector("#time"),
      displayDate: document.querySelector("#date"),
    };
  }

  init() {
    this.updateTime();
  }

  updateTime() {
    const date = new Date();

    Time.SELECTORS.displayTime.textContent = date.toLocaleTimeString();
    Time.SELECTORS.displayDate.textContent = date.toDateString();
  }

  updateTimeAtNextSecond() {
    const date = new Date();
    const secondsUntilNextSecond = 1000 - date.getMilliseconds();
    setTimeout(() => {
      this.updateTime();
      this.updateTimeAtNextSecond();
    }, secondsUntilNextSecond);
  }
}
