export default class Theme {
  constructor(element) {
    this.element = element;
    this.body = document.querySelector("#theme");
    this.themeButton = document.querySelector(".theme");
    this.themeVortexWhite = document.querySelector(".theme-vortex-white");
    this.themeVortexBlack = document.querySelector(".theme-vortex-black");
    this.themeMoon = document.querySelector(".theme-moon");
    this.themeSun = document.querySelector(".theme-sun");

    this.init();
  }

  init() {
    this.themeButton.addEventListener("click", this.changeTheme.bind(this));
    this.setTheme(localStorage.getItem("theme") || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light"));
  }

  changeTheme() {
    this.setTheme(this.body.classList.toggle("theme-dark") ? "dark" : "light");
  }

  setTheme(theme) {
    localStorage.setItem("theme", theme);
    this.body.className = `theme-${theme}`;
    this.changeVortex();
  }

  changeVortex() {
    if (this.themeVortexWhite || this.themeVortexBlack) {

      const isDarkTheme = this.body.classList.contains("theme-dark");

      if (isDarkTheme) {
        this.themeVortexBlack.classList.remove("d-none");
        this.themeVortexWhite.classList.add("d-none");

        this.themeSun.classList.remove("d-none");
        this.themeMoon.classList.add("d-none");
      } else {
        this.themeVortexBlack.classList.add("d-none");
        this.themeVortexWhite.classList.remove("d-none");

        this.themeSun.classList.add("d-none");
        this.themeMoon.classList.remove("d-none");
      }
    }
  }
}
