import { element } from "three/nodes";

export default class Sidebar {
  constructor(element) {
    this.element = element;

    this.sidebarToggle = document.querySelector(".sidebar__toggle");
    this.sidebarToggleIcon = document.querySelector(".sidebar__toggle__icon");
    this.sidebar = document.querySelector(".sidebar");

    this.init();
  }

  init() {
    console.log("Sidebar initialized");
    this.sidebarToggle.addEventListener("click", this.toggleMenu.bind(this));
  }

  toggleMenu() {
    this.sidebar.classList.toggle("sidebar__close");

    if (this.sidebar.classList.contains("sidebar__close")) {
      this.sidebarToggleIcon.classList.remove("fa-chevron-left");
      this.sidebarToggleIcon.classList.add("fa-chevron-right");
    } else {
      this.sidebarToggleIcon.classList.remove("fa-chevron-right");
      this.sidebarToggleIcon.classList.add("fa-chevron-left");
    }
  }
}
