import { GoogleGenerativeAI, HarmBlockThreshold, HarmCategory } from "@google/generative-ai";

export default class Gemini {
  constructor(element) {
    this.element = element;

    this.openButton = document.querySelector("#gemini-open");
    this.input = document.querySelector("#gemini-input");
    this.section = document.querySelector("#gemini-section");
    this.html = document.querySelector("html");
    this.close = document.querySelector(".gemini-close");
    this.loading = document.querySelector(".gemini-loading");

    this.listSection = document.querySelectorAll("section");
    this.footer = document.querySelector("footer");

    this.firstRequest = true;
    this.newRequest = true;

    this.init();
  }

  init() {
    this.openButton.addEventListener("click", this.openGemini.bind(this));
    document.addEventListener("keydown", (event) => event.key === "Enter" && this.gemini(this.input.value));
    this.close.addEventListener("click", this.closeGemini.bind(this));

    if (window.location.hash === "#gemini-ai") {
      this.openGemini();
    }
  }

  openGemini() {
    if (!this.section.classList.contains("d-none")) {
      this.closeGemini();
      return;
    }
    if (document.querySelector("html").classList.contains("nav-is-active")) {
      document.querySelector("html").classList.remove("nav-is-active");
    }
    this.section.classList.toggle("d-none");
    this.html.classList.toggle("overflow-hidden");

    this.listSection.forEach((item) => {
      if (!item.classList.contains("gemini-section")){
        item.classList.add("d-none");
      }
    });
    this.footer.classList.add("d-none");
  }

  closeGemini() {
    this.listSection.forEach((item) => {
      if (!item.classList.contains("gemini-section")){
        item.classList.remove("d-none");
      }
    });
    this.footer.classList.remove("d-none");

    this.section.classList.add("d-none");
    this.html.classList.remove("overflow-hidden");
  }

  gemini(prompt) {
    if (this.firstRequest) {

      const { GoogleGenerativeAI } = require("@google/generative-ai");

      // Access your API key as an environment variable (see "Set up your API key" above)
      const genAI = new GoogleGenerativeAI(process.env.API_KEY_GEMINI);

      const safetySettings = [
        {
          category: HarmCategory.HARM_CATEGORY_HARASSMENT,
          threshold: HarmBlockThreshold.BLOCK_NONE,
        },
        {
          category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
          threshold: HarmBlockThreshold.BLOCK_NONE,
        },
      ];

      // For text-only input, use the gemini-pro model
      const model = genAI.getGenerativeModel({ model: "gemini-1.5-flash", safetySettings });

      this.chat = model.startChat({
        history: []
      });

      this.firstRequest = false;
    }

    if (this.newRequest === true) {

      this.newRequest = false;
      this.input.value = "";
      console.log(prompt);

      this.loading.classList.remove("d-none");

      this.addToPage("prompt", prompt);

      this.run(prompt);
    }
  }

  async run(prompt) {

    const result = await this.chat.sendMessage(prompt);
    const response = await result.response;
    const text = response.text();

    console.log(text);
    this.loading.classList.add("d-none");
    this.addToPage("text", text);
    this.newRequest = true;
  }

  addToPage(type, text) {

    const responses = document.querySelector("#gemini-responses");
    const div = document.createElement("div");
    responses.appendChild(div);

    const nodeList = this.convertToHTML(text);
    nodeList.forEach((node) => {
      node.classList.add(`response-${type}`);
      div.appendChild(node);
    });

    this.scrollDown();
  }

  convertToHTML(text) {
    let nodeList = [];
    const sections = text.split("\n");
    sections.forEach((section) => {
      if (section !== "") {
        const node = document.createElement("p");
        node.innerHTML = section.replace(/\*\*(.*?)\*\*/g, `<strong>$1</strong>`);
        node.innerHTML = node.innerHTML.replace(/(?<!\*)\*(?!\*)/g, "-");
        nodeList.push(node);
      }
    });
    return nodeList;
  }

  scrollDown() {
    const responses = document.querySelector("#gemini-responses");
    responses.scrollTo({
      top: responses.scrollHeight,
      behavior: "smooth"
    });
  }
}
