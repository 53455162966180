export default class BoredApi {
  constructor(element) {
    this.element = element;

    this.randomUrl = "https://www.boredapi.com/api/activity/";
    this.initSelectors();
    this.init();
  }

  initSelectors() {
    this.selectors = {
      boredButton : this.element.querySelector("#bored-button"),
      boredActivity : this.element.querySelector("#bored-activity"),
      boredType : this.element.querySelector("#bored-type"),
      boredParticipants : this.element.querySelector("#bored-participants")
    };
  }

  init() {
    this.selectors.boredButton.addEventListener('click', this.refresh.bind(this));
  }

  async refresh() {
    try {
      const response = await fetch(this.randomUrl);
      const data = await response.json();
      const { activity, type, participants } = data;

      this.selectors.boredActivity.textContent = activity;
      this.selectors.boredType.textContent = type;
      this.selectors.boredParticipants.textContent = participants;

    } catch (error) {
      alert(error);
    }
  }
}
