export default class PorscheGenerator {
  constructor(element) {
    this.element = element;
  }

  static init(type, model) {
    this.porscheModel = document.querySelector(".info-model");
    this.porscheDescription = document.querySelector(".info-description");
    this.porscheLink = document.querySelector(".info-link");
    this.porscheImage = document.querySelector(".generateur-card-img");
    this.porscheImageUrl = "assets/images/porsche/upgrade/png/";
    this.porscheImageUrlExtension = ".png";
    this.porscheLinkUrl = "https://configurator.porsche.com/fr-CA/model-start/";
    this.porscheLinkTextContent = "Configurer une ";

    if (type === "update"){
      this.porscheUpdate(model);
    } else {
      this.porscheClear();
    }
  }

  static porscheUpdate(model) {
    const modelLowCase = model.toLowerCase();
    this.porscheModel.textContent = model;
    this.porscheLink.href = this.porscheLinkUrl + modelLowCase;
    this.porscheLink.textContent = this.porscheLinkTextContent + model;

    this.porscheImage.src = this.porscheImageUrl + modelLowCase + this.porscheImageUrlExtension;
    this.porscheImage.alt = "Porsche" + model;

    switch (model){
      case "718":
        //porscheImage.src = porscheImageUrl + "porsche-718-kahlorr" + porscheImageUrlExtension;
        this.porscheDescription.textContent = "Agile, sportive, biplace, moteur central, performance pure.";
        break;
      case "911":
        //porscheImage.src = porscheImageUrl + "porsche-911-michelle_raponi" + porscheImageUrlExtension;
        this.porscheDescription.textContent = "Icône, élégante, sportive, moteur arrière, héritage riche.";
        break;
      case "Taycan":
        //porscheImage.src = porscheImageUrl + "porsche-taycan-hatefggtsnmslms" + porscheImageUrlExtension;
        this.porscheDescription.textContent = "Électrique, innovante, rapide, luxe moderne, performance électrisante.";
        break;
      case "Panamera":
        //porscheImage.src = porscheImageUrl + "porsche-panamera-monakko" + porscheImageUrlExtension;
        this.porscheDescription.textContent = "Spacieuse, luxueuse, puissante, berline sportive, polyvalente.";
        break;
      case "Macan":
        //porscheImage.src = porscheImageUrl + "porsche-macan-monacocannes" + porscheImageUrlExtension;
        this.porscheDescription.textContent = "Compact, SUV sportif, agile, luxe familial, performant.";
        break;
      case "Cayenne":
        //porscheImage.src = porscheImageUrl + "porsche-cayenne-uehybwrbq" + porscheImageUrlExtension;
        this.porscheDescription.textContent = "Grand, SUV puissant, spacieux, luxe, polyvalence suprême.";
        break;
    }
  }

  static porscheClear() {
    this.porscheModel.textContent = "Porsche";
    this.porscheLink.href = "https://www.porsche.com/";
    this.porscheLink.textContent = "Porsche";

    this.porscheImage.src = this.porscheImageUrl + "placeholder" + this.porscheImageUrlExtension;

    this.porscheDescription.textContent = "Générer un modèle de Porsche";
  }
}
