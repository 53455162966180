export default class Calc {
  constructor(element) {
    this.element = element;

    this.init();
    this.askCalc();
  }

  static get SELECTORS() {
    return {
      calcButton: document.querySelector("#calcbutton"),
      firstNumberInput: document.querySelector("#firstnumber"),
      secondNumberInput: document.querySelector("#secondnumber"),
      displayAnswer: document.querySelector("#calcanswer"),
    };
  }

  init() {
    Calc.SELECTORS.calcButton.addEventListener('click', this.askCalc.bind(this));
  }

  askCalc() {
    let firstNumber = Calc.SELECTORS.firstNumberInput.value;
    let secondNumber = Calc.SELECTORS.secondNumberInput.value;

    Calc.SELECTORS.displayAnswer.textContent = parseFloat(firstNumber) + parseFloat(secondNumber);
  }
}
