import Carousel from './components/Carousel';
import Header from './components/Header';
import Modal from './components/Modal';
import Scrolly from './components/Scrolly';
import Canvas from './components/Canvas';
import Theme from './components/Theme';
import Weather from './components/Weather';
import Time from './components/Time';
import Calc from './components/Calc';
import Wizard from './components/Wizard';
import Spotify from './components/Spotify';
import Stocks from './components/Stocks';
import IpInfo from './components/IpInfo';
import HoverInfo from './components/HoverInfo';
import Cookies from './components/Cookies';
import Threejs from './components/Threejs';
import Calc2 from './components/Calc2';
import BoredApi from './components/BoredApi';
import StringGenerator from './components/StringGenerator';
import PorscheGenerator from './components/PorscheGenerator';
import DraggableElement from './components/DraggableElement';
import Gemini from './components/Gemini';
import Sidebar from './components/Sidebar';
import GoogleMaps from './components/GoogleMaps';

export default class ComponentFactory {
  constructor() {
    this.componentInstances = [];
    this.componentList = {
      Carousel,
      Header,
      Modal,
      Scrolly,
      Canvas,
      Theme,
      Weather,
      Time,
      Calc,
      Wizard,
      Spotify,
      Stocks,
      IpInfo,
      HoverInfo,
      Cookies,
      Threejs,
      Calc2,
      BoredApi,
      StringGenerator,
      PorscheGenerator,
      DraggableElement,
      Gemini,
      Sidebar,
      GoogleMaps,
    };
    this.init();
  }

  init() {
    const components = document.querySelectorAll('[data-component]');

    for (let i = 0; i < components.length; i++) {
      const element = components[i];
      const componentName = element.dataset.component;

      if (this.componentList[componentName]) {
        const instance = new this.componentList[componentName](element);
        this.componentInstances.push(instance);
      } else {
        console.log(`La composante ${componentName} n'existe pas`);
      }
    }
  }
}
