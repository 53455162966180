import PorscheGenerator from "./PorscheGenerator";

export default class StringGenerator {
  constructor(element) {
    this.element = element;
    this.list = this.element.dataset.stringgeneratornames.split(" ");
    this.lastString = null;

    this.buttonGenarator = this.element.querySelector("#stringgenerator-button");
    this.buttonClear = this.element.querySelector("#stringgenerator-button-clear");
    this.canvasGenerator = this.element.querySelector(".title_holder");
    this.init();
  }

  init() {
    this.buttonGenarator.addEventListener("click", this.getString.bind(this));
    this.buttonClear.addEventListener("click", this.clearString.bind(this));
  }

  getString() {
    let stringId = Math.floor(Math.random() * this.list.length);

    if (this.element.dataset.stringgeneratorrepeat === "no-repeat" && this.list[stringId] === this.lastString){
      this.getString();
    } else {
      this.lastString = this.list[stringId];
      this.addString(this.list[stringId]);
    }
  }

  addString(path) {
    const sideLeft = Boolean(Math.round(Math.random()));
    const sideTop = Boolean(Math.round(Math.random()));

    const posX = Math.random() * 45;
    const posY = Math.random() * 50;

    const title = document.createElement("h3");
    title.innerHTML = path;
    this.canvasGenerator.appendChild(title);

    if (sideLeft) {
      title.style.left = `${posX}%`;
    } else {
      title.style.right = `${posX}%`;
    }

    if (sideTop) {
      title.style.top = `${posY}%`;
    } else {
      title.style.bottom = `${posY}%`;
    }

    // Si on est dans le générateur de Porsche
    this.porscheStringGenerator("update", path);
  }

  clearString() {
    while (this.canvasGenerator.firstChild) {
      this.canvasGenerator.removeChild(this.canvasGenerator.firstChild);
    }

    // Si on est dans le générateur de Porsche
    this.porscheStringGenerator("clear", null);
  }

  porscheStringGenerator(type, model) {
    const listComponent = document.querySelectorAll('[data-component]');
    listComponent.forEach((element) => {
      if (element.dataset.component === "PorscheGenerator") {
        PorscheGenerator.init(type, model);
      }
    });
  }
}
