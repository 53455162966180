import SpotifyPlayer from "./SpotifyPlayer";

export default class Spotify {
  constructor(element) {
    this.element = element;

    this.artistUrl = "artists/3U0pUvFUMv6gJiTFnXiueY?si=jGRgNlIvRrKDC44XQoRKVA"; // KIK Account
    this.userUrl = "users/raph2003?si=24166e1d3e0b4b70"; // My Spotify Account
    this.playlistUrl = "playlists/3FFJsQtaqN7LMeIxwjeYuW?si=57d6651330a6481a"; // Playlist Nouveau chapitre
    // Playlist Méli-Mélo 24Hlh5UZ6OLh3cWAvTDk0U?si=f69c76f36ed646d3

    this.askToken();
  }

  static get SELECTORS() {
    return {
      playlistImg: document.getElementById("spotify__playlist__img"),
      playlistTitle: document.getElementById("spotify__playlist__title"),
      playlistUser: document.getElementById("spotify__user__name"),
      playlistLength: document.getElementById("spotify__playlist__length"),
      playlistTableBody: document.querySelector(".spotify__table__body")
    };
  }

  askToken() {
    const url = "https://accounts.spotify.com/api/token";
    const headers = {
      "Content-Type": "application/x-www-form-urlencoded"
    };

    const data = new URLSearchParams();
    data.append("grant_type", "client_credentials");
    data.append("client_id", process.env.SPOTIFY_CLIENTID);
    data.append("client_secret", process.env.SPOTIFY_CLIENTSECRET);

    fetch(url, {
      method: "POST",
      headers: headers,
      body: data
    })
      .then(response => response.json())
      .then(data => {
        this.accessToken = data.access_token; // Corrected variable name
        this.getInformation();
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }

  getInformation() { // Get playlist information
    const playlistHeaders = {
      "Authorization": `Bearer ${this.accessToken}` // Use this.accessToken
    };

    fetch("https://api.spotify.com/v1/" + this.playlistUrl, {
      method: "GET",
      headers: playlistHeaders
    })
      .then(response => response.json())
      .then(playlistData => {
        this.playlistData = playlistData;
        this.showInformation();
      })
      .catch(error => {
        console.error("Error:", error);
      });
  }

  showInformation() {
    //console.log(this.playlistData);
    Spotify.SELECTORS.playlistImg.src = this.playlistData.images[0].url;
    Spotify.SELECTORS.playlistImg.alt = this.playlistData.name;
    Spotify.SELECTORS.playlistTitle.textContent = this.playlistData.name;
    Spotify.SELECTORS.playlistUser.textContent = this.playlistData.owner.display_name;
    Spotify.SELECTORS.playlistUser.href = this.playlistData.owner.external_urls.spotify;
    Spotify.SELECTORS.playlistLength.textContent = this.playlistData.tracks.items.length.toString() + " chansons";

    for (let i = 0; i < this.playlistData.tracks.items.length; i++) {

      // Add a new music in table
      const number = i + 1;
      const newMusic = document.createElement("tr");
      newMusic.setAttribute("id", "table__music" + number.toString());
      Spotify.SELECTORS.playlistTableBody.appendChild(newMusic);

      // Title number
      const newMusicNumber = document.createElement("th");
      newMusicNumber.scope = "row";
      newMusicNumber.textContent = number.toString();
      newMusic.appendChild(newMusicNumber);

      // Music Info (Image + Title + Artist)
      const newMusicInfo = document.createElement("td");
      const newMusicArtist = this.playlistData.tracks.items[i].track.artists[0].name;
      const newMusicArtistUrl = this.playlistData.tracks.items[i].track.artists[0].external_urls.spotify;
      const newMusicTitle = this.playlistData.tracks.items[i].track.name;
      const newMusicUrl = this.playlistData.tracks.items[i].track.external_urls.spotify.split("/").pop();
      const newMusicImg = this.playlistData.tracks.items[i].track.album.images[1].url;
      newMusicInfo.innerHTML = `
      <div class="new__music__info">
        <div class="new__music__img">
            <img src="${newMusicImg}" alt="${newMusicTitle}">
            <div class="spotify__play__hover" data-spotifylink="${newMusicUrl}">
                <svg class="icon icon--play">
                    <use xlink:href="#icon-play"></use>
                </svg>
            </div>
        </div>
        <div class="new__music__txt">
            <p class="new__music__title spotify__play" data-spotifylink="${newMusicUrl}">${newMusicTitle}</p>
            <a href="${newMusicArtistUrl}" target="_blank" class="new__music__artist">${newMusicArtist}</a>
        </div>
      </div>
      `;
      newMusic.appendChild(newMusicInfo);

      // Album Title
      const newAlbumTitle = document.createElement("td");
      newAlbumTitle.textContent = this.playlistData.tracks.items[i].track.album.name;
      newMusic.appendChild(newAlbumTitle);

      //Music Time
      const newMusicTime = document.createElement("td");
      const newMusicTimeMs = this.playlistData.tracks.items[i].track.duration_ms;
      const newMusicTimeMsConvert = new Date(newMusicTimeMs);
      const seconds = newMusicTimeMsConvert.getSeconds();
      const formattedSeconds = String(seconds).padStart(2, '0');
      newMusicTime.textContent = `${newMusicTimeMsConvert.getMinutes()}:${formattedSeconds}`;
      newMusic.appendChild(newMusicTime);
    }

    this.spotifyPlayer();
  }

  spotifyPlayer() {
      if (this.element.dataset.spotifyplayer === "true") {
        new SpotifyPlayer();
      }
  }
}
