import twelvedata from "twelvedata";

export default class Stocks {
  constructor(element) {
    this.element = element;

    this.symbolName = {
      AAPL: "Apple Inc",
      BTC: "Bitcoin US Dollar",
      GOOG: "Alphabet Inc",
      META: "Meta Platforms",
      MSFT: "Microsoft Corp",
      NVDA: "Nvidia Corp",
      TSLA: "Tesla Inc"
    };

    this.init();
  }

  init() {
    console.log("Stocks market");

    const config = {
      key: process.env.API_KEY_TWELVEDATA,
    };

    this.client = twelvedata(config);
    this.askStocks();
  }

  askStocks() {
    let params = {
      symbol: ["AAPL", "BTC/USD", "GOOG", "META", "MSFT", "NVDA", "TSLA"],
      interval: "1min",
      outputsize: 1,
    };

    this.client
      .timeSeries(params)
      .then((data) => {
        console.log(data);
        const listStocks = document.querySelectorAll(".stocks-js");
        listStocks.forEach(element => {
          for (let stockName in data) {
            if (element.id === stockName) {
              element.querySelector(".stocks__card__symbol").textContent = stockName;
              let stockNameClean;
              if (stockName === "BTC/USD") {
                stockNameClean = "BTC";
              } else {
                stockNameClean = stockName;
              }
              element.querySelector(".stocks__card__name").textContent = this.symbolName[stockNameClean];
              element.querySelector(".price__span").textContent = parseFloat(data[stockName]["values"][0]["open"]).toFixed(2);
              element.querySelector(".low__span").textContent = parseFloat(data[stockName]["values"][0]["low"]).toFixed(2);
              element.querySelector(".high__span").textContent = parseFloat(data[stockName]["values"][0]["high"]).toFixed(2);
            }
          }
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }
}
