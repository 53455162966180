import * as THREE from 'three';
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';

export default class Threejs {
  constructor(element) {
    this.element = element;

    const scene = new THREE.Scene();
    const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);

    const renderer = new THREE.WebGLRenderer({
      canvas: document.querySelector("#threejs__bg"),
      antialias: true,
      alpha: true,
    });

    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(window.devicePixelRatio);

    renderer.gammaOutput = true;
    renderer.gammaFactor = 2.2;

    renderer.shadowMap.enabled = true;
    renderer.shadowMap.type = THREE.PCFSoftShadowMap;

    window.addEventListener('resize', () => {
      // Mettre à jour la scène
      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(window.devicePixelRatio);

      // Mettre à jour la caméra
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
    });

    camera.position.setX(-3);
    camera.position.setY(2);
    camera.position.setZ(3);

    const loader = new GLTFLoader();

    loader.load( '../assets/threejs/chambre3d.glb', function ( gltf ) {
      scene.add( gltf.scene );
    }, undefined, function ( error ) {
      console.error( error );
    } );

    const pointLight = new THREE.PointLight(0xffffff, 100);
    pointLight.position.set(3, 4.5, 1.5);

    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(pointLight, ambientLight);

    const gridHelper = new THREE.GridHelper(10, 15);
    gridHelper.position.setY(-0.15);
    scene.add(gridHelper);

    const controls = new OrbitControls(camera, renderer.domElement);

    function animate() {
      requestAnimationFrame(animate);

      controls.update();

      renderer.render(scene, camera);
    }

    animate();

    this.init();
  }

  init() {
    console.log("Threejs");
  }
}
