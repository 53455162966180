export default class Weather {
  constructor(element) {
    this.element = element;
    this.lastCity = null;
    this.init();
  }

  static get SELECTORS() {
    return {
      metaThemeColor: document.querySelector("#meta-theme-color"),
      weatherTime: document.querySelector(".weather-page"),
      weatherBackground: document.querySelector(".weather-background-img"),
      weatherButton: document.querySelector(".weatherButton"),
      cityInput: document.querySelector(".city"),
      cityNameElement: document.querySelector(".city-name-name"),
      countryElement: document.querySelector(".city-name-country"),
      temperatureElement: document.querySelector(".city-temp"),
      weatherDescriptionElement: document.querySelector(".weather-description"),
      weatherIcon: document.getElementById("city-icon"),
    };
  }

  init() {
    this.weatherChange();
    Weather.SELECTORS.weatherButton.addEventListener('click', this.weatherChange.bind(this));
    document.addEventListener('keydown', (event) => event.key === 'Enter' && this.weatherChange());
  }

  async weatherChange() {
    const cityName = Weather.SELECTORS.cityInput.value;
    if (cityName === this.lastCity) {
      return; // Pas besoin de refaire l'appel API si la même ville est sélectionnée
    }
    this.lastCity = cityName;

    const apiurl = `https://api.openweathermap.org/data/2.5/weather?q=${cityName}&appid=${process.env.API_KEY_OPENWEATHERMAP}&units=metric&lang=fr`;

    try {
      const response = await fetch(apiurl);
      const data = await response.json();
      const { main, name, sys, weather } = data;
      const dayTime = weather[0].icon.replace(/\d+/g, '');
      const icon = `https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/${weather[0].icon}.svg`;

      if (dayTime === "d") {
        Weather.SELECTORS.metaThemeColor.content = "#f9ac4d";
      } else if (dayTime === "n") {
        Weather.SELECTORS.metaThemeColor.content = "#6f65ca";
      }

      Weather.SELECTORS.weatherTime.id = `weather-${dayTime}`;
      Weather.SELECTORS.weatherBackground.src = `assets/images/weather/${dayTime}.svg`;
      Weather.SELECTORS.cityNameElement.textContent = name;
      Weather.SELECTORS.countryElement.textContent = sys.country;
      Weather.SELECTORS.temperatureElement.textContent = Math.round(main.temp);
      Weather.SELECTORS.weatherIcon.src = icon;
      Weather.SELECTORS.weatherIcon.alt = weather[0].description;
      Weather.SELECTORS.weatherDescriptionElement.textContent = weather[0].description;
    } catch (error) {
      alert("Ceci n'est pas une ville valide");
    }
  }
}

