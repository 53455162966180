export default class Calc2 {
  constructor(element) {
    this.element = element;
    this.input = document.querySelector("#calc-input");
    this.styleSheet = document.styleSheets[0];

    this.initSelectors();
    this.init();
  }

  initSelectors() {
    this.selectors = {
      buttonClear: this.element.querySelector("#calc-clear"),
      buttonDelete: this.element.querySelector("#calc-delete"),
      buttonDivide: this.element.querySelector("#calc-divide"),
      buttonMultiply: this.element.querySelector("#calc-multiply"),
      buttonMinus: this.element.querySelector("#calc-minus"),
      buttonPlus: this.element.querySelector("#calc-plus"),
      buttonDot: this.element.querySelector("#calc-dot"),
      buttonResult: this.element.querySelector("#calc-result"),
      buttonAns: this.element.querySelector("#calc-ans"),
      listNumber: this.element.querySelectorAll(".calc__number"),
      listOperation: this.element.querySelectorAll(".calc__operation"),
      historyList: this.element.querySelector("#calc-history-list"),
    };
  }

  init() {
    this.selectors.buttonClear.addEventListener('click', this.clearInput.bind(this));
    this.selectors.buttonDelete.addEventListener('click', this.deleteInput.bind(this));
    this.selectors.listNumber.forEach((element) => {
      element.addEventListener('click', () => {
        const number = element.value;
        this.addToInput(number);
      });
    });
    this.selectors.listOperation.forEach((element) => {
      element.addEventListener('click', () => {
        const operation = element.value;
        this.addToInput(operation);
      });
    });
    this.selectors.buttonAns.addEventListener('click', () => {
      const number = this.selectors.buttonAns.value;
      this.addToInput(number);
    });
    this.selectors.buttonDot.addEventListener('click', () => {
      const dot = this.selectors.buttonDot.value;
      this.addToInput(dot);
    });
    this.selectors.buttonResult.addEventListener('click', this.askCalc.bind(this));

    // Keydown
    document.addEventListener('keydown', this.keyDown.bind(this));
  }

  askCalc() {
    const expression = this.input.value;
    try {
      let result = eval(expression);
      if (result === undefined){
        throw new Error("L'opération n'est pas défini");
      } else if (result === Infinity) {
        throw new Error("On ne peut pas divisé par zéro");
      }
      this.styleSheet.insertRule(`.calc__result::after { content: "ANS=`+ result +`"; }`, this.styleSheet.cssRules.length);

      this.selectors.buttonAns.value = result;
      let history = expression + "=" + result;
      this.addHistory(history);
      this.clearInput();
    } catch (e) {
      alert("L'opération comporte une erreur : " + e.message);
    }
  }

  addToInput(element) {
    const inputValue = this.input.value;
    this.input.value = inputValue + element;
  }

  addHistory(element){
    let newLi = document.createElement("li");
    this.selectors.historyList.appendChild(newLi);
    newLi.textContent = element;
  }

  clearInput(){
    this.input.value = "";
  }

  deleteInput() {
    let value = this.input.value;
    this.input.value = value.slice(0, -1);
  }

  keyDown(keydown) {

    // Gérer la touche Enter
    if (keydown.key === 'Enter') {
      this.askCalc();
      return; // Sortir immédiatement de la fonction après avoir traité 'Enter'
    }

    // Définir les touches valides pour les opérations mathématiques et autres caractères spécifiques
    const validKeys = new Set(['(', ')', '-', '+', '*', '/', '=', '.']);

    // Gérer les chiffres et les touches valides pour les opérations
    if (keydown.key.match(/^[0-9]$/) || validKeys.has(keydown.key)) {
      this.addToInput(keydown.key);
    }
    // Gérer les touches spéciales Backspace et Delete
    else if (keydown.key === "Backspace") {
      this.deleteInput();
    } else if (keydown.key === "Delete") {
      this.clearInput();
    }
  }

}
